import { Controller } from "@hotwired/stimulus"
import { useTransition } from "stimulus-use"

export default class SidebarController extends Controller {
  static values = {
    name: String,
  }

  connect() {
    useTransition(this, {
      element: this.element,
    })
  }

  toggle(event) {
    if (this.forCurrentSidebar(event)) {
      this.toggleTransition()
    } else {
      this.leave()
    }
  }

  hide(event) {
    if (this.forCurrentSidebar(event)) {
      this.leave()
    }
  }

  private

  forCurrentSidebar({ detail: { sidebarName } }) {
    return !sidebarName || this.nameValue === sidebarName
  }
}
