import { Controller } from "@hotwired/stimulus"

export default class InputResetController extends Controller {
  static targets = ["input"]

  reset({ params: { value } }) {
    this.inputTarget.value = value
    this.inputTarget.dispatchEvent(new InputEvent("input"))
    this.inputTarget.dispatchEvent(
      new Event("change", { bubbles: true, cancelable: true }),
    )
  }
}
