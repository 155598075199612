import { Controller } from "@hotwired/stimulus"
import Sortablejs from "sortablejs"

export default class SortableController extends Controller {
  static values = {
    animation: { type: Number, default: 150 },
    handle: String,
    positionInput: String,
  }

  connect() {
    this.sortable = new Sortablejs(this.element, {
      handle: this.handleValue,
      animation: this.animationValue,
      onUpdate: () => {
        this.updatePositions()
      },
    })
  }

  disconnect() {
    this.sortable.destroy()
    this.sortable = undefined
  }

  updatePositions() {
    const draggableElements = [...this.element.children]
    for (const [index, child] of draggableElements.entries()) {
      const positionInputs = child.querySelectorAll(this.positionInputValue)
      for (const input of positionInputs) {
        input.value = index + 1
      }
    }
  }
}
