import ParentController from "@stimulus-components/rails-nested-form"

export default class RailsNestedFormController extends ParentController {
  static values = {
    childIndex: {
      type: String,
      default: "NEW_RECORD",
    },
    wrapperSelector: {
      type: String,
      default: ".nested-form-wrapper",
    },
  }

  add(e) {
    e.preventDefault()

    const template =
      this.templateTargets.length > 1
        ? this.templateTargets.find(
            (target) =>
              e.params.template ===
              target.getAttribute(`data-${this.identifier}-template`),
          )
        : this.templateTarget

    const content = template.innerHTML.replaceAll(
      this.childIndexValue,
      Date.now().toString(),
    )

    this.targetTarget.insertAdjacentHTML("beforebegin", content)

    const event = new CustomEvent("rails-nested-form:add", { bubbles: true })
    this.element.dispatchEvent(event)
  }
}
